import React , {useEffect, useState} from 'react'
import { getSettingsData } from '../Hooks/UseBackend';
import { BsFacebook } from 'react-icons/bs'; 
import { FaTwitterSquare, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    const [settingdata,setsettingdata] = useState([])
    useEffect(() =>{
        getData()
    })

    const getData =async() =>{
        var settingdata = await getSettingsData();
  setsettingdata(settingdata);
    }
    return(
        <div className='footer'>
             <span className='greengradientfooter'></span>
            <div className='container container-theme'>
                <div className='row'>
                    
                    {/* <div className='col-sm-4'>
                        <ul className='text-sm-start ps-0'>
                           <li>
                                <a target="blank" href={settingdata && settingdata[0]?.Description}>
                                    <span className='fa fa-facebook-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[1]?.Description}>
                                    <span className='fa fa-twitter-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[2]?.Description}>
                                    <span className='fa fa-instagram'></span>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div className='col-sm-8 col-12'>
                        <p className='textclr'>COPY RIGHT @ 2023 .ALL RIGHTS RESERVED</p>
                    </div>
                    {/* <div className='col-sm-4 col-12 text-end'>
                       <a href='/'><BsFacebook/></a>
                       <a href='/'><FaTwitterSquare/></a>
                       <a href='/'><FaInstagram/></a>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer