export const  style = {
      
    minWidth: '300px',
    minHeight: '45px',
    border: '1px solid #8649ab',
    padding: '16px',
    borderRadius: '15px',
    background: '#0d0d0d',
    color: '#75E943',
    
}

export const iconTheme = {
    primary: '#75E943',
    secondary: '#8649ab',
}

export const  position = {
    position: "bottom-center"
}