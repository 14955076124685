import React, { useState,useEffect } from 'react';
import {Button, Modal} from 'react-bootstrap'
import metamask from '../../Assets/images/metamask.png'
import walletconnect from '../../Assets/images/walletconnect.png'
import trustwallet from '../../Assets/images/trustwallet.png'
import { useWeb3 } from "../../Hooks/UseWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../../Hooks/useWallet';
import { getAccount } from "../../Hooks/useAccounts";
import { sleep } from "../../Hooks/useContract";
import {style,iconTheme,position} from '../../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';

const Connect = (props) =>{
    const[connect, setConnect] = useState(true)
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("")
 
    useEffect(() => {
     setAccountInfo(getAccount);
     
 }, [accountInfo]); 
 
 const MetaMask = async () => {
     await MetamaskWallet()
     await sleep(1000)
     await useWeb3();
     setAccountInfo(localStorage.getItem("accountInfo"))
     if (localStorage.accountInfo) {
 
        
         toast.success("Wallet Connected successfully", {
             position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
         setTimeout(() => {
             
             window.location.reload()
         }, 1000);
        
     }
 }
 
 const Binance = async () => {
     await BinanceWallet()
     await sleep(1000)
     await useWeb3();
     setAccountInfo(localStorage.getItem("accountInfo"))
     if (localStorage.accountInfo) {
 
         toast.success("Wallet Connected successfully", {
             position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
         setTimeout(() => {
             
             window.location.reload()
         }, 1000);
 
        
     }
 
 }
 const enableWallet = async () => {
     const provider = await WalletConnect()
     set_walletConnect(provider)
 
     setAccountInfo(localStorage.getItem("accountInfo"))
    
     if (localStorage.getItem("accountInfo")) {
         setConnect(false)
         toast.success("Wallet Connected successfully", {
             position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
        
         setTimeout(() => {
             
             window.location.reload()
         }, 1000);
     }
 
 }
    return(
        <>
         <Modal show={connect} centered size="md">
            <Modal.Header className='pb-0'>
            <h5 className='cowa'><span className='titlespan'>Co</span>nnect Wallet</h5>
            <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span  className='fa fa-times'></span></button>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <div className='d-flex flex-1 gap-3 mb-3'>
                     <div className='yellowbox connect text-center p-4' onClick={()=>MetaMask()}>
                         <img src={metamask} />
                         <p className='mb-0 mt-2'>Metamask</p>
                     </div>
                     <div className='yellowbox connect text-center p-4' onClick={enableWallet}>
                         <img src={walletconnect} />
                         <p className='mb-0 mt-2'>Wallet Connect</p>
                     </div>
                </div>
                <div className='yellowbox connect text-center p-4'  onClick={MetaMask}>
                         <img src={trustwallet} />
                         <p className='mb-0 mt-2'>Trust Wallet</p>
                     </div>
            </Modal.Body>
         
        </Modal>
        </>
    )
}

export default Connect